import * as React from "react"
import { Link } from "gatsby"

const HeaderButton = () => (
  <>
    <Link
      to="/contact"
    >
      <button className="bg-site-blue hover:bg-blue-900 text-white text-lg font-extrabold tracking-wider uppercase px-8 py-4 ml-6">Contact Us Today</button>
    </Link>
  </>
)

export default HeaderButton