import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import SimpleReactValidator from 'simple-react-validator'

class NewsletterForm extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator()
    this.state = {
      email: '',
      status: '',
    }
  }

  handleFormSubmit = e => {
    e.preventDefault();

    if (this.validator.allValid()) {
      addToMailchimp(this.state.email)
      .then(data => {
        this.setState({
          status: data.result
        })
      })
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }

  render() {
    return (
      <form className='mb-3'>
        <div className='flex items-center justify-center'>
          <div className="w-8/12">
            <input
              className="w-full bg-white py-3 px-4 font-body text-site-blue"
              id="email"
              type="email"
              placeholder='name@domain.com'
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
              required
            />
            {this.validator.message('email', this.state.email, 'required|email', {className: 'text-red-500 text-xs italic'})}
          </div>

          <div className="w-4/12">
            <button
              className="bg-site-blue hover:bg-blue-900 text-white text-md  text-center tracking-wider font-extrabold uppercase border-4  ml-2 py-3 px-4"
              type='submit'
              onClick={e => this.handleFormSubmit(e)}
            >
              SUBMIT
            </button>
          </div>
        </div>

        {this.state.status === 'success' &&
          <div className='mt-3'>
            <div className="text-white font-semibold">
              Thank you for subscribing!
            </div>
          </div>
        }

        {this.state.status === 'error' &&
          <div className='mt-3'>
            <div className="text-white font-semibold">
              This email has already been added.
            </div>
          </div>
        }


      </form>
    )
  }
}

export default NewsletterForm