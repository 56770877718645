import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const HeaderLogo = () => (
  <>
    <div className="hidden md:flex items-center">
      <Link to="/">
        <StaticImage
          src="../../images/logos/tiarna-logo.png"
          width="150"
          quality="95"
          alt="Tiarna Logo"
        />
      </Link>
    </div>

    <div className="md:hidden flex items-center">
      <Link to="/">
        <StaticImage
          src="../../images/logos/tiarna-logo.png"
          width="90"
          quality="95"
          alt="Tiarna Logo"
        />
      </Link>
    </div>
  </>
)

export default HeaderLogo