import * as React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import styled from 'styled-components'
import { LocationMarkerIcon, MailIcon, PhoneIcon } from "@heroicons/react/solid"

import LinkedinIcon from '../../assets/linkedin.svg'
import FacebookIcon from '../../assets/facebook.svg'
import HeaderLogoFooter from "../header/header-logo-footer"
import NewsletterForm from "../forms/newsletter-form"

const UserwayWidget = loadable(() =>
  pMinDelay(import('./userway-widget'), 3000)
)

const StyledP = styled.p`
  font-size: 8px;
`

const Footer = () => {
  return (
    <footer className='bg-site-dark-blue px-4 pt-16 pb-10 border-t-2 border-site-blue'>
      <div className='relative w-11/12 max-w-screen-xl flex flex-col-reverse md:flex-row pb-8 md:w-10/12 mx-auto border-b border-site-red'>
        <div className='w-full md:w-1/2 flex items-end justify-start'>
          <HeaderLogoFooter />
          <div className="block md:hidden lg:block ml-6">
            <StaticImage
              alt="AMO Certified Badge"
              src={
                "../../images/sections/amo.png"
              }
              formats={["auto", "webp", "avif"]}
              width={100}
            />
          </div>
          <div className="hidden md:block lg:hidden ml-6">
            <StaticImage
              alt="AMO Certified Badge"
              src={
                "../../images/sections/amo.png"
              }
              formats={["auto", "webp", "avif"]}
              width={80}
            />
          </div>
        </div>

        <div className='w-full md:w-1/2 flex flex-col items-center md:items-end mb-8 md:mb-0'>
          <div className='w-full flex flex-col items-center lg:items-start'>
            <h2 className='font-display text-white text-lg font-medium lg-4 mb-2'>
              Sign up to receive the latest Tiarna news & insights in your inbox 
              <StaticImage
                alt="Arrow Down"
                src={
                  "../../images/sections/arrow-light-down.png"
                }
                formats={["auto", "webp", "avif"]}
                width={21}
                className="inline-block w-2 ml-2 mt-2"
              />
            </h2>

            <NewsletterForm />

            <StyledP className='font-body text-white'>I agree to receive email communication from GroLens related to this offer, new upcoming events and content. You may edit your communications preferences and/or opt-out at any time.</StyledP>
          </div>
        </div>
      </div>

      <div className='relative w-11/12 max-w-screen-xl flex flex-col-reverse lg:flex-row lg:w-10/12 mx-auto mt-8'>
        <div className='w-full lg:w-1/2 flex flex-col items-center lg:items-start mt-8 md:mt-0'>
          <div className="flex flex-col lg:flex-row lg:items-start">
            <p className='font-body text-sm text-white text-center lg:text-left mr-0 lg:mr-8'>Copyright © {new Date().getFullYear()} <Link to="/" className="hover:underline">Tiarna</Link></p>
            <Link to='/terms' className='text-sm text-white text-center lg:text-left hover:underline font-body mt-6 mr-0 lg:mr-8 lg:mt-0 mx-0'>Terms of Service</Link>
            <Link to='/privacy' className='text-sm text-white text-center lg:text-left hover:underline font-body mt-6 mr-0 lg:mr-8 lg:mt-0 mx-0'>Privacy Policy</Link>
            <Link to='/accessibility' className='text-sm text-white text-center lg:text-left hover:underline font-body mt-6 mr-0 lg:mr-8 lg:mt-0 mx-0'>Accessibility</Link>
            <Link to='/careers' className='text-sm text-white text-center lg:text-left hover:underline font-body mt-6 lg:mt-0 mx-0'>Careers</Link>
          </div>

          <div className="mt-4">
            <p className='text-sm text-gray-700  text-center lg:text-left font-body'>Website by <a className="hover:underline hover:text-white" href='https://www.fastweb.dev' target='_blank' rel='noopener noreferrer'>fastweb.dev</a></p>
          </div>
        </div>

        <div className='w-full lg:w-1/2 flex flex-col items-center lg:items-end'>
          <div className="flex flex-wrap items-center justify-center mb-4">
            <div className="flex items-center mr-6 mb-2 lg:mb-0">
              <LocationMarkerIcon className="h-4 w-4 text-site-red" aria-hidden="true" />
              <a
                href='https://goo.gl/maps/15cHJZxWZbMQyP5c6'
                target='_blank'
                rel='noopener noreferrer'
                className="text-white text-sm hover:underline ml-1"
              >
                2603 Main Street
              </a>
            </div>
            <div className="flex items-center lg:mr-6 mb-2 lg:mb-0">
              <MailIcon className="h-4 w-4 text-site-red" aria-hidden="true" />
              <a
                href='mailto:info@tiarna.com'
                target='_blank'
                rel='noopener noreferrer'
                className="text-white text-sm hover:underline ml-1"
              >
                info@tiarna.com
              </a>
            </div>
            <div className="flex items-center">
              <PhoneIcon className="h-4 w-4 text-site-red" aria-hidden="true" />
              <a
                href='tel:949-833-8334'
                target='_blank'
                rel='noopener noreferrer'
                className="text-white text-sm hover:underline ml-1"
              >
                949-833-8334
              </a>
            </div>
          </div>

          <div className="flex items-center">
            <p className="text-white text-sm mr-4">Follow Us:</p>
            <div className='w-4 mr-4'><a href='https://www.linkedin.com/company/tiarna-real-estate-services-inc./' target='_blank' rel='noopener noreferrer'><LinkedinIcon /><span className='hidden'>LinkedIn</span></a></div>

            <div className='w-4 mr-4'><a href='https://www.facebook.com/pages/Tiarna-Real-Estate-Services/163031227049640' target='_blank' rel='noopener noreferrer'><FacebookIcon /><span className='hidden'>Facebook</span></a></div>
          </div>
        </div>
      </div>

      <UserwayWidget />
    </footer>
  )
}

export default Footer