import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const HeaderLogoFooter = () => (
  <>
    <div className="flex md:hidden lg:flex items-center">
      <Link to="/">
        <StaticImage
          src="../../images/logos/tiarna-logo.png"
          width="231"
          quality="95"
          alt="Tiarna Logo"
          className="mb-1"
        />
      </Link>
    </div>

    <div className="hidden md:flex lg:hidden items-center">
      <Link to="/">
        <StaticImage
          src="../../images/logos/tiarna-logo.png"
          width="200"
          quality="95"
          alt="Tiarna Logo"
          className="mb-1"
        />
      </Link>
    </div>
  </>
)

export default HeaderLogoFooter